.sidebar {
    background: #ed1c24;
}
.sidebar .nav-link.active {
    color: #ed1c24 !important;
    background: #ffffff;
}
.sidebar .nav-link:hover {
    color: #ed1c24 !important;
    background: #ffffff;
}
.sidebar .nav-link.active .nav-icon {
    color: #ed1c24;
}
.sidebar .nav-link .nav-icon {
    color: #ffffff;
}
.sidebar .nav-link:hover .nav-icon {
    color: #ed1c24;
}
.breadcrumb .breadcrumb-item a {
	color: #ed1c24;
}
.sidebar .nav-link:hover {
  color: #ed1c24 !important;
}
