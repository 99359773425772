/* MODAL */

.header-fixed .app-header {
  z-index: 102 !important;
  top: 0;
}
.styles_modal__gNwvD {
  width: 100%;
  padding: 0px;
}

.marginmessage {
  margin-top: -15px;
  margin-bottom: 0;
}
.borderless {
  border: 0px;
}
.borderless tr td,
.borderless tr th {
  border-right: 0px;
  border-left: 0px;
  padding: 3px;
}
.loader-outer {
  position: relative;
}
.overlay {
  position: absolute;
  width: 100%;
  min-height: 100vh;
  background: #0000007a;
  z-index: 100;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
}

span.file-name {
  position: relative;
  top: -28px;
  background: #fff;
  left: 104px;
  width: calc(100% - 120px);
  white-space: nowrap;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
}

label.float-right {
  position: relative;
  top: 10px;
}
.DraftEditor-root {
  padding: 10px;
  border: 1px solid #e4e7ea;
  margin-top: 10px;
}
.public-DraftEditorPlaceholder-root {
  top: 25px;
}
.public-DraftEditor-content[contenteditable="true"] {
  height: 380px;
}
.contentcss div {
  height: 45px;
  overflow: hidden;
  position: relative;
}
.contentcss {
  position: relative;
}
.contentcss span.dotcss {
  position: absolute;
  right: -4px;
  top: 20px;
}

/****css by Designer******/
.react-datepicker-wrapper {
  width: 100%;
}
.main .container-fluid {
  padding: 0px 15px;
}
@media (max-width: 1199.98px) {
  .tblWidth {
    min-width: 800px;
    width: 800px;
  }
  .width-100 {
    width: 100px;
  }
  .width-150 {
    width: 150px;
  }
  .app-header .navbar-brand {
    top: 0;
    left: 50px;
    margin-left: 0px;
    width: 40px;
  }
  .app-header .navbar-brand img {
    border-radius: 5px;
  }
  .app-header .navbar-toggler {
    outline: none;
  }
}
@media (max-width: 575.98px) {
  .headerEmail a {
    max-width: 120px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
.bg-primary {
  background-color: #fff !important;
}
.card.bg-primary {
  border-color: #fff !important;
}

#profile .dropdown-menu {
  left: -128px !important;
  margin-right: 10px;
}
.eye-icons i {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.sub-menu-heading {
  display: flex;
  gap: 5px;
}
.sub-menu-text {
  padding-top: 5px;
}
.back-button {
  background-color: #7ad1ed;
  border: none;
  border-radius: 5px;
  width: 35px;
}

.download-button {
  align-items: center;
  margin-right: 15px;
  margin-left: 15px;
  gap: 5px;
}

.header-button {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.error-message {
  color: red;
  margin-top: 5px;
}

.sidebar .nav-link:hover::before {
  filter: invert(11%) sepia(77%) saturate(7346%) hue-rotate(354deg)
    brightness(107%) contrast(86%);
}

.sidebar-minimized .sidebar .sidebar-minimizer {
  background-color: #ed1c24;
}
.sidebar-minimized .sidebar .sidebar-minimizer {
  background-color: rgba(0, 0, 0, 0.2);
}
.sidebar .sidebar-minimizer::before {
  background-image: url("../../assets/images/white-arrow.svg") !important;
  rotate: 90deg;
  filter: invert(0%) sepia(0%) saturate(5183%) hue-rotate(217deg)
    brightness(103%) contrast(240%);
}
.brand-minimized .app-header .navbar-brand {
  width: 155px !important;
}
.sidebar .nav-dropdown-toggle::before {
  background-image: url("../../assets/images/white-arrow.svg") !important;
  rotate: 90deg;
  filter: invert(0%) sepia(0%) saturate(5183%) hue-rotate(217deg)
    brightness(103%) contrast(240%);
}

.custom-svg {
  transform: translateX(
    -10px
  ) !important; /* Modify the transform as per your requirement */
  fill: #ffffff !important; /* Set the color to white */
}

.sidebar .nav-dropdown.open > .nav-dropdown-toggle::before {
  rotate: 90deg;
}
.sidebar .nav-dropdown > .nav-dropdown-toggle::before {
  rotate: 270deg;
  margin-left: 10px;
}
.sidebar .nav-link {
  padding: 0.75rem 5px 0.75rem 10px;
}
.tblWidth .table_status {
  min-width: 160px;
}

.actions-btn {
  min-width: 100px;
}
.sidebar-fixed .sidebar {
  top: 55px;
  z-index: 999;
}

/* search dropdown css */
.react-dropdown-select-content > span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}
.react-dropdown-select .react-dropdown-select-content {
  flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  overflow: hidden;
}
.m100Px{
  margin-top: 150px !important;
}
